<template>
  <div class="mores">
    <my-header style="margin-right:20px"></my-header>
    <div class="title f14 c333 text-left pt10 mb25">我的课程<span class="f30 pl15">My course</span></div>
    <div class="list overAuto flex col-top flex-wrap" v-if="!noMsg">
      <div class="item cup" v-for="(item, index) in list" :key="index" @click="goChoice(item)"><img :src="item.images" ></div>
      <div class="noMsg" v-if="noMsg">
        <div class="img"><img src="../../assets/img/courseMsg.jpg" ></div>
        <div class="text-center f24 c333 pt10">查询无结果</div>
      </div>
    </div>
    <div class="list" v-if="noMsg">
      
    </div>
  </div>
</template>

<script>
  import myHeader from '../../components/head.vue';
  export default {
    name: 'more',
    components: {
      myHeader
    },
    data(){
      return{
        noMsg: false,
        list: [],
      }
    },
    mounted() {
      this.getList();
    },
    methods:{
      async getList(){
        var params = {
          adsId: this.$route.query.id
        }
        var res = await this.$api.get('/api/project_collection/collectionList', params);
        if(res.code == 0){
          this.list = res.data.collectionInfo;
          if(this.list.length){
            this.noMsg = false;
          }else{
            this.noMsg = true;
          }
        }
      },
      goChoice(item){
        var id = item.collectionId;
        this.$router.push('/home/choice?id='+id);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mores{
    height: 100%;
    padding: 40px 20px 50px 40px;
    .list{
      // overflow: auto;
      max-height: calc(100% - 130px);
      position: relative;
      &.overAuto{
        overflow: auto;
      }
      .item{
        text-align: left;
        font-size: 0;
        width: 347px;
        height: 200px;
        margin-right: 30px;
        margin-bottom: 30px;
        border-radius: 6px;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .title span{
      color: #5b68fb;
    }
  }
</style>
